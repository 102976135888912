<template>
  <div>
    <div class="d-flex flex-row ma-8">
      <div class="text-h5">MANUAL CURATION</div>
      <div class="d-flex flex-column ml-auto">
        <v-btn :disabled="!isScanAllowed" width="200" color="primary" @click="doScanSamples">Scan Samples</v-btn>
        <div class="caption mx-auto">
          Last Status: <span class="font-weight-bold" :class="lastStatusClass">{{ lastStatus }}</span>
        </div>
      </div>
    </div>
    <div class="mx-8">
      <div class="d-flex flex-row">
        <div class="mt-5 body-1 mr-4">SV Types:</div>
        <v-radio-group v-model="selectedSvType" row @change="onSvTypeChanged">
          <v-radio class="mr-8" v-for="t in svTypes" :key="t" :label="t" :value="t"></v-radio>
        </v-radio-group>
      </div>
      <v-data-table  :headers="sampleHeaders" :items="samples"  :disable-pagination="true" hide-default-footer group-by="sample_name" :show-group-by="true" class="elevation-1" item-key="id">
        <template v-slot:item.actions="{ item }" >
          <div class="d-flex flex-row">
            <v-btn class="mr-2" color="primary" outlined @click="downloadResult(item)" small><v-icon> mdi-download</v-icon> Result</v-btn>
            <v-btn color="secondary"  small :to="{ name: 'Curation', params: { sampleId: item.id } }" outlined><v-icon> mdi-play</v-icon> Curation</v-btn>

          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { getCurationTaskStatus, scanSamples, getSamples, getCurationCsvResult } from "@/api/curation.js";
export default {
  name: "ManualCuration",
  data: () => ({
    lastStatus: null,
    intrervalID: null,
    rawSamples: [],
    selectedSvType: "(ALL)",
    svTypes: ["(ALL)", "INS", "DUP", "DEL", "SUB", "INV", "ADD"],
  }),
  computed: {
    samples() {
      return this.rawSamples.map((item) => {
        let t = {};
        t["id"] = item.id;
        t["sample_name"] = item.sample_name;
        t["sv_type"] = item.sv_type;
        t["date"] = item.date;
        t["total_variants"] = item.total_variants;
        let curated_variants = 0;

        Object.keys(item.curated_variants_count).forEach((x) => {
          t[x + "_curated"] = item.curated_variants_count[x];
          curated_variants += item.curated_variants_count[x];
        });
        t["curated_variants"] = curated_variants;

        return t;
      });
    },
    sampleHeaders() {
      let headers = [
        {
          text: " Sample",
          align: "start",
          sortable: true,
          value: "sample_name",
          class: "accent",
        },
        { text: "Type", value: "sv_type", class: "accent" },
        { text: "Date", value: "date", class: "accent" },
        { text: "Total Variants", value: "total_variants", class: "accent", groupable: false, align: "right", sortable: false, divider: true },
        // { text: "Curated Variants", value: "curated_variants", class: "accent", groupable: false, sortable: false, divider: true },
      ];
      if (this.rawSamples.length > 0) {
        let users = new Set();
        this.rawSamples.forEach((x) => {
          Object.keys(x.curated_variants_count).forEach((u) => users.add(u));
        });
        headers = headers.concat(
          Array.from(users).map((user) => {
            return { text: user.toUpperCase(), value: user + "_curated", class: "accent", align: "center", groupable: false, divider: true };
          })
        );
      }
      headers.push({ text: "Download Result", value: "actions", class: "accent", align: "center", width: "130px", sortable: false, groupable: false });
      return headers;
    },
    isScanAllowed() {
      return this.lastStatus == "FAILURE" || this.lastStatus == "SUCCESS" || this.lastStatus == "REVOKED" || this.lastStatus == null || this.lastStatus=="NEVER SCANNED";
    },
    lastStatusClass() {
      let color = "red--text";
      switch (this.lastStatus) {
        case "FAILURE":
          color = "red--text";
          break;
        case "PENDING":
          color = "orange--text";
          break;
        case "RECEIVED":
          color = "orange--text";
          break;
        case "RETRY":
          color = "orange--text";
          break;
        case "REVOKED":
          color = "red--text";
          break;
        case "STARTED":
          color = "orange--text";
          break;
        case "SUCCESS":
          color = "green--text";
          break;
      }
      return color;
    },
  },
  created() {
    this.getLastStatus();

    this.loadSamples();
  },
  watch: {
    isScanAllowed(val) {
      if (val) {
        clearInterval(this.intrervalID);
        this.intrervalID = null;
      } else {
        if (!this.intrervalID) {
          this.intrervalID = setInterval(() => this.getLastStatus(), 3000);
        }
      }
    },
    lastStatus(newVal,oldVal){
      if(newVal=='SUCCESS' && oldVal =='STARTED'){
        this.loadSamples();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.intrervalID);
  },
  methods: {
    onSvTypeChanged() {
      this.loadSamples();
    },
    getLastStatus() {
      const _this = this;
      getCurationTaskStatus((res) => {
        _this.lastStatus = res.status;
      },
        (err)=>{ if(err.status == 404) {
          _this.lastStatus = 'NEVER SCANNED';
        }}
      );
      // console.log("getLastStatus");
    },
    doScanSamples() {
      const _this = this;
      scanSamples((res) => {
        _this.lastStatus = res.status;
        // console.log(res)
      }, "Scanning the files started");
    },
    downloadResult(item) {
      // const _this = this
      console.log(item);
      getCurationCsvResult(item.id,(res)=>{
        let blob = new Blob([res], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${item.sample_name}-${item.sv_type}-${item.date.replaceAll('-','')}-curated.tsv`;
        link.click(); 
      })
    },
    loadSamples() {
      const svType = this.selectedSvType == "(ALL)" ? null : this.selectedSvType;
      getSamples( 1, 1000, (res) => {
        if (svType !== null) {
          this.rawSamples = res.results.filter((item) => item.sv_type == svType);
        } else {
          this.rawSamples = res.results;
        }
      });
    },
  },
};
</script>